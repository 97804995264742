import * as React from "react"
import {graphql, Link, navigate} from "gatsby"
import {StaticImage} from "gatsby-plugin-image";
import ButtonRounded from "../components/buttons/buttonRounded/ButtonRounded";
import {
  container,
  contentContainer,
  imageContainer,
  image,
  message,
  returnTextContainer,
  buttonGoBack
} from "../assets/pageStyles/404.module.scss";
import {useTranslation} from "gatsby-plugin-react-i18next";

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;


const NotFoundPage = () => {
  const {t}=useTranslation();

  return (
    <main className={container}>
        <div className={contentContainer}>
          <div className={imageContainer}>
            <StaticImage className={image} src={"./../images/404.svg"} alt="Logo" objectFit="contain"
                         placeholder="tracedSVG"/>
          </div>
          <div className={message}>
            <span>{t('notFoundPage')}</span>
          </div>
          <div className={returnTextContainer}>
            <ButtonRounded className={buttonGoBack} color={true} label="Return to index page" onClick={(e) => navigate("/")}/>
          </div>
        </div>
    </main>
  )
}

export default NotFoundPage

export const Head = () => <title>Not found</title>
